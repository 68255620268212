var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._v(
        "\n  这里显示出计算公式和考核系数规则，后面设置的详细规则来显示。\n  分开显示翻译和制作的，把\n  "
      ),
      _c("g-title", [_vm._v("翻译规则")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }