var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "detail-layout",
    { style: _vm.css, attrs: { title: _vm.title } },
    [
      _vm.isAddProduction
        ? _c("div", { attrs: { slot: "actions" }, slot: "actions" }, [
            _c(
              "div",
              { staticClass: "actions flex jc-end mb-4" },
              [
                _c(
                  "a-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab == "1",
                        expression: "tab == '1'",
                      },
                    ],
                    staticClass: "mr-3",
                    attrs: { icon: "save", loading: _vm.nextProcessing },
                    on: { click: _vm.saveProduction },
                  },
                  [_vm._v("保存")]
                ),
                _c(
                  "a-button",
                  {
                    attrs: { type: "primary", loading: _vm.nextProcessing },
                    on: {
                      click: function ($event) {
                        _vm.primaryButtonClick(Number(_vm.tab) + 1 + "", true)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.primaryButtonText))]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.nextProcessing } },
        [
          _c(
            "a-tabs",
            {
              attrs: { "default-active-key": "1", activeKey: _vm.tab },
              on: { change: _vm.tabChange },
            },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "基本信息" } },
                [
                  _c("tab-production", {
                    ref: "productionTab",
                    attrs: {
                      "is-loading-user-list": _vm.isLoadingUserList,
                      "is-loading-platform-list": _vm.isLoadingPlatformList,
                      "production-data": _vm.detail,
                      platformList: _vm.platformList,
                      adminUsers: _vm.adminUsers,
                      isJf: _vm.isJf,
                      tab: _vm.tab,
                      positionId: _vm.positionId,
                      "is-add-production": _vm.isAddProduction,
                    },
                    on: {
                      changePlatForm: _vm.changePlatForm,
                      setAdmin: _vm.setAdmin,
                      changeProduction: _vm.changeProduction,
                      loadData: _vm.getDetail,
                      setPagePattern: _vm.setPagePattern,
                      getIsedit: _vm.getIsedit,
                    },
                  }),
                ],
                1
              ),
              !_vm.isAddProduction
                ? _c(
                    "a-tab-pane",
                    {
                      key: "2",
                      attrs: {
                        tab: "章节",
                        disabled:
                          _vm.$refs.productionTab &&
                          (_vm.$refs.productionTab.isEditProduction ||
                            !_vm.$refs.productionTab.formData.productionName),
                      },
                    },
                    [
                      _c("tab-chapter", {
                        ref: "firstTab",
                        attrs: {
                          columns: _vm.columns,
                          parent: _vm.detail,
                          calcUnits: _vm.calcUnits,
                          isJf: _vm.isJf,
                          tab: _vm.tab,
                        },
                        on: { loadData: _vm.getDetail },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isAddProduction && !_vm.isJf
                ? _c(
                    "a-tab-pane",
                    {
                      key: "3",
                      attrs: {
                        tab: "任务",
                        calcUnits: _vm.calcUnits,
                        disabled:
                          _vm.$refs.productionTab &&
                          (_vm.$refs.productionTab.isEditProduction ||
                            !_vm.$refs.productionTab.formData.productionName),
                      },
                    },
                    [
                      _c("tab-tasks", {
                        ref: "tasks",
                        attrs: {
                          parent: _vm.detail,
                          tasksTab: _vm.tasksTab,
                          tab: _vm.tab,
                        },
                        on: { tabChange: _vm.tabChange },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$refs.productionTab &&
              _vm.$refs.productionTab.formData.systemTypes &&
              _vm.$refs.productionTab.formData.systemTypes.length &&
              !_vm.isJf
                ? _c(
                    "a-tab-pane",
                    {
                      key: "4",
                      attrs: {
                        disabled:
                          _vm.$refs.productionTab &&
                          (_vm.$refs.productionTab.isEditProduction ||
                            !_vm.$refs.productionTab.formData.productionName),
                        tab: "翻译设定",
                      },
                    },
                    [
                      _c("tab-guide", {
                        attrs: {
                          parent: _vm.detail,
                          tab: _vm.tab,
                          isAdmin: _vm.isAdmin,
                          userInfo: _vm.userInfo,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$refs.productionTab &&
              _vm.$refs.productionTab.formData.isLocal
                ? _c(
                    "a-tab-pane",
                    {
                      key: "6",
                      attrs: {
                        disabled:
                          _vm.$refs.productionTab &&
                          (_vm.$refs.productionTab.isEditProduction ||
                            !_vm.$refs.productionTab.formData.productionName),
                        tab: "术语表",
                      },
                    },
                    [
                      _c("tab-terms-wrapper", {
                        attrs: {
                          userInfo: _vm.userInfo,
                          parent: _vm.detail,
                          isAdmin: _vm.isAdmin,
                          tab: _vm.tab,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isJf
                ? _c(
                    "a-tab-pane",
                    {
                      key: "7",
                      attrs: {
                        tab: "阶段",
                        disabled:
                          _vm.$refs.productionTab &&
                          (_vm.$refs.productionTab.isEditProduction ||
                            !_vm.$refs.productionTab.formData.productionName),
                      },
                    },
                    [
                      _c("tab-stage", {
                        ref: "tabStage",
                        attrs: {
                          adminUsers: _vm.stageAdminUsers,
                          "is-add-production": _vm.isAddProduction,
                          parent: _vm.detail,
                          isAdmin: _vm.isAdmin,
                          platform: _vm.platform,
                          tab: _vm.tab,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$refs.productionTab &&
              _vm.$refs.productionTab.formData.isLocal
                ? _c(
                    "a-tab-pane",
                    {
                      key: "8",
                      attrs: {
                        tab: "作品详情",
                        disabled:
                          _vm.$refs.productionTab &&
                          (_vm.$refs.productionTab.isEditProduction ||
                            !_vm.$refs.productionTab.formData.productionName),
                      },
                    },
                    [
                      _c("tab-info", {
                        ref: "tabInfo",
                        attrs: {
                          isAddProduction: _vm.isAddProduction,
                          parent: _vm.detail,
                          tab: _vm.tab,
                        },
                        on: { getIsedit: _vm.getIsedit },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$refs.productionTab &&
              _vm.$refs.productionTab.formData.hasKpi &&
              !_vm.isJf
                ? _c(
                    "a-tab-pane",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "mytask:detail:button:coefficientPar",
                          expression: "'mytask:detail:button:coefficientPar'",
                        },
                      ],
                      key: "10",
                      attrs: {
                        disabled:
                          _vm.$refs.productionTab &&
                          (_vm.$refs.productionTab.isEditProduction ||
                            !_vm.$refs.productionTab.formData.productionName),
                        tab: "难度系数",
                      },
                    },
                    [
                      _c("rule", {
                        ref: "rule",
                        attrs: { parent: _vm.detail, platform: _vm.platform },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }