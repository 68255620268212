<!-- 这个页面是作品详情，注意isAddProduction -->
<template>
  <detail-layout :title="title" :style="css">
    <div slot="actions" v-if="isAddProduction">
      <div class="actions flex jc-end mb-4">
        <a-button icon="save" class="mr-3" @click="saveProduction" :loading="nextProcessing" v-show="tab == '1'"
          >保存</a-button
        >
        <a-button type="primary" @click="primaryButtonClick(Number(tab) + 1 + '', true)" :loading="nextProcessing">{{
          primaryButtonText
        }}</a-button>
      </div>
    </div>
    <!-- {{ { tab } }} -->
    <a-spin :spinning="nextProcessing">
      <a-tabs :default-active-key="'1'" :activeKey="tab" @change="tabChange">
        <a-tab-pane key="1" tab="基本信息">
          <tab-production
            @changePlatForm="changePlatForm"
            @setAdmin="setAdmin"
            @changeProduction="changeProduction"
            @loadData="getDetail"
            @setPagePattern="setPagePattern"
            ref="productionTab"
            :is-loading-user-list="isLoadingUserList"
            :is-loading-platform-list="isLoadingPlatformList"
            :production-data="detail"
            :platformList="platformList"
            :adminUsers="adminUsers"
            @getIsedit="getIsedit"
            :isJf="isJf"
            :tab="tab"
            :positionId="positionId"
            :is-add-production="isAddProduction"
          ></tab-production>
        </a-tab-pane>
        <a-tab-pane
          key="2"
          tab="章节"
          :disabled="
            $refs.productionTab &&
              ($refs.productionTab.isEditProduction || !$refs.productionTab.formData.productionName)
          "
          v-if="!isAddProduction"
        >
          <tab-chapter
            :columns="columns"
            @loadData="getDetail"
            :parent="detail"
            :calcUnits="calcUnits"
            :isJf="isJf"
            :tab="tab"
            ref="firstTab"
          ></tab-chapter>
        </a-tab-pane>
        <a-tab-pane
          key="3"
          tab="任务"
          :calcUnits="calcUnits"
          :disabled="
            $refs.productionTab &&
              ($refs.productionTab.isEditProduction || !$refs.productionTab.formData.productionName)
          "
          v-if="!isAddProduction && !isJf"
        >
          <tab-tasks :parent="detail" @tabChange="tabChange" :tasksTab="tasksTab" :tab="tab" ref="tasks"></tab-tasks>
        </a-tab-pane>
        <a-tab-pane
          :disabled="
            $refs.productionTab &&
              ($refs.productionTab.isEditProduction || !$refs.productionTab.formData.productionName)
          "
          v-if="
            $refs.productionTab &&
              $refs.productionTab.formData.systemTypes &&
              $refs.productionTab.formData.systemTypes.length &&
              !isJf
          "
          key="4"
          tab="翻译设定"
        >
          <tab-guide :parent="detail" :tab="tab" :isAdmin="isAdmin" :userInfo="userInfo"></tab-guide>
        </a-tab-pane>
        <a-tab-pane
          :disabled="
            $refs.productionTab &&
              ($refs.productionTab.isEditProduction || !$refs.productionTab.formData.productionName)
          "
          v-if="$refs.productionTab && $refs.productionTab.formData.isLocal"
          key="6"
          tab="术语表"
        >
          <!-- v-if="
            $refs.productionTab &&
              $refs.productionTab.formData.systemTypes &&
              $refs.productionTab.formData.systemTypes.some(item => item == 'IMAGE_TRANSLATION_SYSTEM')
          " -->
          <tab-terms-wrapper :userInfo="userInfo" :parent="detail" :isAdmin="isAdmin" :tab="tab"></tab-terms-wrapper>
        </a-tab-pane>
        <a-tab-pane
          key="7"
          tab="阶段"
          v-if="!isJf"
          :disabled="
            $refs.productionTab &&
              ($refs.productionTab.isEditProduction || !$refs.productionTab.formData.productionName)
          "
        >
          <tab-stage
            :adminUsers="stageAdminUsers"
            ref="tabStage"
            :is-add-production="isAddProduction"
            :parent="detail"
            :isAdmin="isAdmin"
            :platform="platform"
            :tab="tab"
          ></tab-stage>
        </a-tab-pane>
        <a-tab-pane
          key="8"
          tab="作品详情"
          :disabled="
            $refs.productionTab &&
              ($refs.productionTab.isEditProduction || !$refs.productionTab.formData.productionName)
          "
          v-if="$refs.productionTab && $refs.productionTab.formData.isLocal"
        >
          <tab-info
            :isAddProduction="isAddProduction"
            :parent="detail"
            ref="tabInfo"
            :tab="tab"
            @getIsedit="getIsedit"
          ></tab-info>
        </a-tab-pane>
        <!--         <a-tab-pane
          key="9"
          tab="系数参数"
          :disabled="$refs.productionTab && $refs.productionTab.isEditProduction"
          v-if="$refs.productionTab && $refs.productionTab.formData.isLocal && $refs.productionTab.formData.hasKpi"
          >
          <product-detail domain="TASK_DETAIL" type="PRODUCT" :parent="detail"> </product-detail>
        </a-tab-pane> -->
        <a-tab-pane
          v-has="'mytask:detail:button:coefficientPar'"
          :disabled="
            $refs.productionTab &&
              ($refs.productionTab.isEditProduction || !$refs.productionTab.formData.productionName)
          "
          v-if="$refs.productionTab && $refs.productionTab.formData.hasKpi && !isJf"
          key="10"
          tab="难度系数"
        >
          <rule :parent="detail" :platform="platform" ref="rule" />
        </a-tab-pane>
        <!-- <a-tab-pane key="10" tab="难度参数">
          <tab-difficulty :parent="detail" :tab="tab"></tab-difficulty>
        </a-tab-pane> -->
        <!-- <a-tab-pane key="8" tab="考核结算参数">
        <tab-rule-params :parent="detail" :tab="tab"></tab-rule-params>
      </a-tab-pane> -->
      </a-tabs>
    </a-spin>
  </detail-layout>
</template>

<script>
import TabChapter from './tab-chapter'
import TabTerms from './tab-terms'
import TabProduction from './tab-production'
import { getAction, postAction } from '@/api/manage'
import { OpenDrawerMixins } from '@/mixins/OpenDrawerMixins'
import TabGuide from './tab-guide.vue'
import TabTermsTemplate from './tab-terms-template.vue'
import TabStage from './tab-stage.vue'
import TabTasks from './tab-tasks.vue'
import TabTermsWrapper from './tab-terms-wrapper.vue'
import TabRuleParams from './tab-rule-params.vue'
import TabInfo from './tab-info.vue'
import TabDifficulty from './tab-difficulty.vue'
import ProductDetail from './ProductDetail.vue'
import rule from './rule'
import { USER_INFO } from '@/store/mutation-types'
import { simpleDebounce } from '@/utils/util'
import Vue from 'vue'
export default {
  components: {
    TabChapter,
    TabTerms,
    TabProduction,
    TabGuide,
    TabTermsTemplate,
    TabStage,
    TabTasks,
    TabTermsWrapper,
    TabRuleParams,
    TabInfo,
    TabDifficulty,
    ProductDetail,
    rule
  },
  mixins: [OpenDrawerMixins],
  data() {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: 80,
          scopedSlots: { customRender: 'sequenceNo' }
        },
        {
          title: '标题',
          dataIndex: 'chapterName',
          width: 80,
          scopedSlots: { customRender: 'chapterName' }
        },
        {
          title: '结算数量',
          width: 80,
          dataIndex: 'calcUnits',
          scopedSlots: { customRender: 'calcUnits' }
        },
        {
          title: '当前阶段',
          width: 80,
          dataIndex: 'stageName'
        },
        {
          title: '当前人员',
          width: 120,
          scopedSlots: { customRender: 'user' },
          dataIndex: 'user'
        },
        {
          title: '当前截止日期',
          width: 150,
          dataIndex: 'currDeadline'
        },
        {
          title: '章节状态',
          width: 120,
          dataIndex: 'chapterState',
          scopedSlots: { customRender: 'chapterState' }
        },
        {
          title: '预警情况',
          width: 80,
          dataIndex: 'warningState'
        },
        {
          title: '交稿日期',
          width: 130,
          dataIndex: 'deliveryDate'
        },
        {
          title: '文本量',
          width: 80,
          dataIndex: 'textAmount'
        },
        {
          title: '原文字数',
          width: 110,
          dataIndex: 'originTextAmount'
        },
        {
          title: '译文字数',
          width: 110,
          dataIndex: 'translationTextAmount'
        }
      ],
      /* tab: sessionStorage.getItem('current_name')
        ? sessionStorage.getItem('current_name') == '2' || sessionStorage.getItem('current_name') == '3'
          ? '1'
          : sessionStorage.getItem('current_name')
        : '1', */
      tab: '1',
      isLoad: false,
      color: '',
      title: '',
      isAddProduction: false,
      saveProcessing: false,
      nextProcessing: false,
      detail: {
        adminUser: {},
        sequenceNo: 1,
        adminUserId: null,
        platformId: null,
        id: 0,
        departmentIds: [],
        productionName: null,
        temporaryName: null,
        foreignName: null,
        calcUnits: [],
        hasChapterTitle: null,
        hasChapterCoverImage: null,
        isTongChou: false
      },
      platformList: [],
      positionList: [],
      stageAdminUsers: [],
      adminUsers: [],
      userList: [],
      isLoadingUserList: false,
      isLoadingPlatformList: false,
      positionId: null,
      isEdit: false,
      /* defaultActiveKey: sessionStorage.getItem('current_name')
        ? sessionStorage.getItem('current_name') == '2' || sessionStorage.getItem('current_name') == '3'
          ? '1'
          : sessionStorage.getItem('current_name')
        : '1', */
      defaultActiveKey: '1',
      tasksTab: '1',
      calcUnits: [],
      ruleShow: false,
      platform: {
        isLocal: true
      },
      userInfo: {},
      isAdmin: false,
      isJf: false
    }
  },
  computed: {
    /* this.platform.isLocal ? (this.tab == '9' ? '完成' : '下一步') : this.tab == '7' ? '完成' : '下一步' */
    primaryButtonText() {
      return this.tab == '7' ? '完成' : '下一步'
    },
    css() {
      let color = this.color
      return {
        '--theme-color': color
      }
    }
  },
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
          }
        }
      }
    }, 50)
    /*     if (!this.isAddProduction) {
      this.defaultActiveKey = sessionStorage.getItem('current_name') || '1'
      this.tab = sessionStorage.getItem('current_name') || '1'
    }
    if (this.isAddProduction) this.tab = '1' */
  },
  methods: {
    async getFormData() {
      const productionId = this.$route.params.id
      const res = await getAction('/factorView/product', {
        productionId
      })
      try {
        if (res.code == 200) {
          this.formData = res.data
          if (res.data.difficulties) {
            if (res.data.difficulties.length) {
              this.ruleShow = true
            }
          }
        } else {
          this.$message.error(res.msg || res.message)
        }
      } catch (err) {
        this.$message.error(err)
      }
    },
    setPagePattern(calcUnits) {
      this.calcUnits = calcUnits
    },
    changePlatForm(platformInfo) {
      this.detail.platformId = platformInfo.id
      this.platform = platformInfo.platform
    },
    setAdmin(data) {
      this.adminUsers = data.arr
      this.stageAdminUsers = data.stageArr
    },
    getIsedit(data) {
      this.isEdit = data
    },
    tabChange(tab) {
      this.tasksTab = tab
      sessionStorage.setItem('current_name', tab)
      if (!this.$refs.productionTab.formData.hasKpi) {
        this.columns = [
          {
            title: '序号',
            dataIndex: 'sequenceNo',
            width: 80,
            scopedSlots: { customRender: 'sequenceNo' }
          },
          {
            title: '标题',
            dataIndex: 'chapterName',
            width: 100,
            scopedSlots: { customRender: 'chapterName' }
          },
          {
            title: '结算数量',
            width: 140,
            dataIndex: 'calcUnits',
            scopedSlots: { customRender: 'calcUnits' }
          },
          {
            title: '当前阶段',
            width: 80,
            dataIndex: 'stageName'
          },
          {
            title: '当前人员',
            width: 100,
            scopedSlots: { customRender: 'user' },
            dataIndex: 'user'
          },
          {
            title: '当前截止日期',
            width: 120,
            dataIndex: 'currDeadline'
          },
          {
            title: '章节状态',
            width: 100,
            dataIndex: 'chapterState',
            scopedSlots: { customRender: 'chapterState' }
          },
          {
            title: '预警情况',
            width: 80,
            dataIndex: 'warningState'
          },
          {
            title: '交稿日期',
            width: 120,
            dataIndex: 'deliveryDate'
          }
        ]
      } else {
        this.columns = [
          {
            title: '序号',
            dataIndex: 'sequenceNo',
            width: 80,
            scopedSlots: { customRender: 'sequenceNo' }
          },
          {
            title: '标题',
            dataIndex: 'chapterName',
            width: 80,
            scopedSlots: { customRender: 'chapterName' }
          },
          {
            title: '结算数量',
            width: 140,
            dataIndex: 'calcUnits',
            scopedSlots: { customRender: 'calcUnits' }
          },
          {
            title: '当前阶段',
            width: 80,
            dataIndex: 'stageName'
          },
          {
            title: '当前人员',
            width: 120,
            scopedSlots: { customRender: 'user' },
            dataIndex: 'user'
          },
          {
            title: '当前截止日期',
            width: 120,
            dataIndex: 'currDeadline'
          },
          {
            title: '章节状态',
            width: 100,
            dataIndex: 'chapterState',
            scopedSlots: { customRender: 'chapterState' }
          },
          {
            title: '预警情况',
            width: 80,
            dataIndex: 'warningState'
          },
          {
            title: '交稿日期',
            width: 120,
            dataIndex: 'deliveryDate'
          },
          {
            title: '文本量',
            width: 80,
            dataIndex: 'textAmount'
          },
          {
            title: '原文字数',
            width: 110,
            dataIndex: 'originTextAmount'
          },
          {
            title: '译文字数',
            width: 110,
            dataIndex: 'translationTextAmount'
          }
        ]
      }
      if (this.$refs.productionTab) this.$refs.productionTab.changeFlag = false
      if (this.defaultActiveKey == 3) {
        this.$nextTick(async () => {
          await this.$refs.tasks.cancel()
          /* await this.$refs.tasks.kanbanCancel() */
          if (!this.$refs.tasks.isChangeTab) {
            this.defaultActiveKey = tab
            return
          }
          this.defaultActiveKey = tab
          if (this.tab == '1') this.getAllPlatform()
          if (!this.isEdit) {
            if (this.isAddProduction) {
              console.log('tabChange', { tab })
              this.primaryButtonClick(tab)
            } else {
              this.changeTab(tab)
            }
          } else {
            this.$confirm({
              title: '提示',
              content: '您尚未保存数据，需要保存吗？',
              okText: '保存',
              cancelText: '切换',
              maskClosable: true,
              onOk: async () => {
                if (this.tab == '8') await this.$refs.tabInfo.editProduction()
                if (this.tab == '1') await this.$refs.productionTab.editProduction()
                this.isEdit = false
                if (this.isAddProduction) {
                  console.log('tabChange', { tab })
                  this.primaryButtonClick(tab)
                } else {
                  this.changeTab(tab)
                }
              },
              onCancel: () => {
                this.isEdit = false
                /* if (this.tab == '1') this.$refs.productionTab.cancel() */
                if (this.isAddProduction) {
                  console.log('tabChange', { tab })
                  this.primaryButtonClick(tab)
                } else {
                  this.changeTab(tab)
                }
              }
            })
          }
        })
      } else {
        this.defaultActiveKey = tab
        if (this.tab == '1') this.getAllPlatform()
        if (!this.isEdit) {
          if (this.isAddProduction) {
            console.log('tabChange', { tab })
            this.primaryButtonClick(tab)
          } else {
            this.changeTab(tab)
          }
        } else {
          this.$confirm({
            title: '提示',
            content: '您尚未保存数据，需要保存吗？',
            okText: '保存',
            cancelText: '切换',
            maskClosable: true,
            onOk: async () => {
              if (this.tab == '8') await this.$refs.tabInfo.editProduction()
              if (this.tab == '1') await this.$refs.productionTab.editProduction()
              this.isEdit = false
              if (this.isAddProduction) {
                console.log('tabChange', { tab })
                this.primaryButtonClick(tab)
              } else {
                this.changeTab(tab)
              }
            },
            onCancel: () => {
              this.isEdit = false
              if (this.isAddProduction) {
                console.log('tabChange', { tab })
                this.primaryButtonClick(tab)
              } else {
                this.changeTab(tab)
              }
            }
          })
        }
      }
    },
    getAllPlatform() {
      return getAction('/platform/list?pageSize=1000').then(res => {
        if (res.success) {
          this.platformList = res.data.records
          this.isLoadingPlatformList = false
          if (this.platformList.length == 1 && this.platformList[0].active) {
            this.$refs.productionTab.formData.platformId = this.platformList[0].id
            this.$refs.productionTab.oldData.platformId = this.platformList[0].id
            this.detail.platformId = this.platformList[0].id
          }
          let userInfo = Vue.ls.get(USER_INFO)
          if (!userInfo.roles.some(r => r.roleCode == 'admin' || r.roleCode == 'boss')) {
            this.platformList = this.platformList.filter(item => {
              return userInfo.departs.some(d => {
                return d.id == item.department.departId
              })
            })
          }
          this.$nextTick(() => {
            if (this.$refs.productionTab) this.$refs.productionTab.handelPlatformChange()
            if (this.$route.query.tab) {
              this.$nextTick(() => {
                this.tab = this.$route.query.tab
              })
            }
          })
          this.handlerDepartmentIds()
        }
      })
    },
    changeProduction(formData) {
      this.title = formData.productionName
    },
    getAllPosition() {
      return getAction('/sys/position/list').then(res => {
        if (res.success) {
          this.positionList = res.data.records
          this.positionId = this.positionList.find(one => one.name == '统筹')?.id
        }
      })
    },
    getAllAdminUsers() {
      return postAction('/sys/user/queryUser', {
        departmentIds: this.detail.departmentIds?.length ? this.detail.departmentIds : null
      }).then(({ data }) => {
        this.adminUsers = data
        this.stageAdminUsers = data
        this.isLoadingUserList = false
        let userInfo = Vue.ls.get(USER_INFO)
        if (userInfo.roles.some(item => item.roleCode == 'boss' || item.roleCode == 'admin')) return
        if (this.$refs.productionTab) {
          this.$refs.productionTab.formData.adminUserId = userInfo.id
          this.$refs.productionTab.oldData.adminUserId = userInfo.id
        }
      })
    },
    getDetail() {
      return getAction('/production/get', {
        id: this.detail.id
      }).then(({ data }) => {
        // this.detail = Object.assign({}, result)
        this.detail = data
        let userInfo = Vue.ls.get(USER_INFO)
        if (userInfo.roles.some(item => item.roleCode == 'boss' || item.roleCode == 'admin' || item.roleCode == 'hr')) {
          this.detail.isTongChou = true
        } else if (userInfo.roles.some(item => item.roleCode == 'tongchou')) {
          if (this.detail.adminUsers[0].userId == userInfo.id) {
            this.detail.isTongChou = true
          } else {
            this.detail.isTongChou = false
          }
        }
        console.log('getDetail', { 'this.detail': this.detail })
        this.calcUnits = data.calcUnits
        this.title = this.detail.productionName || this.detail.foreignName
        if (this.$route.query && this.$route.query.isTranslate) {
          if (this.$route.query.isTranslate == '术语表') {
            this.tab = '6'
          } else {
            this.tab = '4'
          }
        }
      })
    },
    primaryButtonClick: simpleDebounce(async function(tab, isBtnClick = false) {
      this.primaryButtonClickSim(tab, isBtnClick)
    }, 500),
    async primaryButtonClickSim(tab, isBtnClick = false) {
      console.log('primaryButtonClick', { tab, 'this.isAddProduction': this.isAddProduction })
      if (this.isAddProduction && !this.detail.id && this.tab == '1') {
        // 如果当前是新增并且还没新增，并且标签页为1
        this.nextProcessing = true
        this.$refs.productionTab.saveProduction(async (success, id) => {
          this.nextProcessing = false
          if (success) {
            this.isLoad = true
            this.nextProcessing = false
            /*             if (!this.isLoad) {
              this.nextProcessing = true
              const res = await postAction('/productDetail/copy/up', {
                type: 'PRODUCT',
                relationId: id
              })
              if (res.success) {
                this.isLoad = true
                this.nextProcessing = false
                this.detail.id = id
                this.changeTab(tab)
                return
              } else {
                this.nextProcessing = false
                this.$message.error(res.message)
              }
            } */
            this.detail.id = id
            this.changeTab(tab)
            // this.$router.push({ path: '/production/manage/detail/' + id })
            return
          } else {
            this.nextProcessing = false
            this.$message.error(res.message)
          }
        })
        return
      }
      if (!this.isLoad) {
        this.nextProcessing = true
        this.isLoad = true
        this.nextProcessing = false
        this.changeTab(tab)
        /*         const res = await postAction('/productDetail/copy/up', {
          type: 'PRODUCT',
          relationId: this.detail.id
        })
        if (res.success) {
          this.isLoad = true
          this.nextProcessing = false
          this.changeTab(tab)
          return
        } else {
          this.nextProcessing = false
          this.$message.error(res.message)
        } */
      }
      if (isBtnClick) {
        if (this.primaryButtonText == '完成') {
          this.$router.push({ path: '/production/manage' })
        }
      }
      this.changeTab(tab)
    },
    changeTab(tab) {
      // FIXME 这里不能硬编码，会维护困难
      console.log('changeTab start', { tab })
      this.tab = tab
      if (this.tab == '5') {
        this.tab = '6'
      }
      if (tab == '2' && this.isAddProduction) {
        this.tab = '7' //tab切换需求修改
      }
      if (this.tab == '7' && this.isAddProduction) {
        this.$refs.tabStage?.loadData()
      }
      if (this.tab == '3') {
        if (this.$refs.tasks) {
          this.$refs.tasks.getKanBanData()
          this.$refs.tasks.isEditSelect = false
        }
      }
      if (tab == '7' && !this.isAddProduction) {
        this.$refs.tabStage?.loadData()
      }
      if (tab == '10' && !this.isAddProduction) {
        this.$refs.rule?.getFormData()
        this.$refs.rule?.getDifficultyFactor()
        this.$refs.rule?.getDetail()
        this.$refs.rule?.getStageList()
      }
      console.log('changeTab end', { 'this.tab': this.tab })
    },
    saveProduction: simpleDebounce(async function() {
      this.saveProductionSim()
    }, 500),
    saveProductionSim() {
      this.nextProcessing = true
      this.$refs.productionTab.saveProduction(async (success, id) => {
        this.nextProcessing = false
        if (success) {
          this.isLoad = true
          this.nextProcessing = false
          this.detail.id = id
          this.$router.push({ path: '/production/manage' })
          /*           const res = await postAction('/productDetail/copy/up', {
            type: 'PRODUCT',
            relationId: id
          })
          if (res.success) {
            this.isLoad = true
            this.nextProcessing = false
            this.detail.id = id
            this.$router.push({ path: '/production/manage' })
            return
          } else {
            this.nextProcessing = false
            this.$message.error(res.message)
          } */
        }
      })
    },
    handlerDepartmentIds() {
      console.log('handlerDepartmentIds', { 'this.detail.platformId': this.detail.platformId })
      let departId = this.platformList.find(one => one.id == this.detail.platformId)?.department?.departId
      this.detail.departmentIds = departId ? [departId] : []
      console.log('production-detail detail.platformId watched', {
        'this.detail.departmentIds': this.detail.departmentIds
      })
      if (this.$refs.productionTab) {
        if (departId) {
          this.$refs.productionTab.departmentId = departId
          this.$refs.productionTab.getAllAdminUsers()
        }
      }
    }
  },
  created() {
    let id = this.$route.params.id
    this.isLoadingUserList = true
    this.isLoadingPlatformList = true
    if (id === 'new') {
      // 如果是新建
      this.title = '新建'
      this.isAddProduction = true
      document.title = '新建作品 - 漫小崔之家'
      this.getAllPlatform()
        .then(this.getAllPosition)
        .then(this.getAllAdminUsers)
    } else {
      this.detail.id = id
      // 查询详情和平台信息，然后找到对应平台的部门ids
      // 通过部门ids和职位id查询用户
      Promise.all([this.getDetail(), this.getAllPlatform()])
        .then(this.getAllPosition)
        .then(this.getAllAdminUsers)
      this.getFormData()
    }
    this.userInfo = Vue.ls.get(USER_INFO)
    this.isAdmin = this.userInfo.roles.some(item => {
      return item.roleCode == 'admin' || item.roleCode == 'boss'
    })
    this.isJf = this.userInfo.roles.some(item => {
      return item.roleCode == 'jiafang'
    })
  },
  watch: {
    'detail.platformId': {
      handler() {
        this.handlerDepartmentIds()
        if (this.isLoadingUserList) return
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item-label .ant-form-item-required:before {
  color: #fff !important;
}
/deep/ .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: var(--theme-color) !important;
}
</style>
