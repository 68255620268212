<template>
  <div>
    这里显示出计算公式和考核系数规则，后面设置的详细规则来显示。
    分开显示翻译和制作的，把
    <g-title>翻译规则</g-title>
  </div>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import GTitle from '../../../components/my/g-title.vue'
export default {
  mixins: [JeecgListMixin],
  components: {
    GTitle
  },
  props: {
    parent: {
      type: Object,
      default: () => {},
    },
    tab: {
      type: String,
      default: '1',
    },
  },
  watch: {
    tab(n) {
      // this.loadData();
    },
  },
  data() {
    return {
    }
  },
  methods: {},
  created() {},
  computed: {
  },
}
</script>

<style lang='stylus'></style>